<script setup>
import { onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { parseString } from 'xml2js'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import ModalAsignarLotes from '../components/modalAsignarLotes.vue'
import { error, messageConfirmSave } from '../../../../../../../libs/mensajes'
import { useRouter } from 'vue-router'
import { helper } from '../../../../../../../utils/helper'

const router = useRouter()
const _RecepcionPedidosService = ref(new RecepcionPedidosService())
const isDragging = ref(false)
const medicamentosList = ref([])
const bodegas = ref([])
const filteredBodegas = ref([])
const infoLotes = ref([])
const loadingItems = ref(false)
const modalAsignarLotes = ref()
const op = ref()
const Invoice = ref({
  InvoiceNumber: null,
  CardName: null,
  DocDate: null,
  Comments: null,
  JournalMemo: null,
  DocTotal: null,
  DocTotalBeDi: null,
  TaxTotal: null,
  DiscTotal: 0,
  Items: []
})

// Funcion para abrir el modal de asignar lotes
const openModalAsignarLotes = (data) => {
  modalAsignarLotes.value.openModal(data)
}

//
const toggle = (event, data) => {
  infoLotes.value = data.BatchNumbers
  op.value.toggle(event)
}

// Calcular el total de cada línea (items), multiplicando el valor de la unidad por la cantidad
const calcularTotalLinea = (data) => {
  // Agregar a la data el total calculado
  data.LineTotalCalculado = data.Price * data.InvoiceQuantity
  return data.Price * data.InvoiceQuantity
}

// Funcion para asignar la cantidad al lote
const asignarCantidad = (data) => {
  data.BatchNumbers.forEach((x) => {
    x.Quantity = data.InvoiceQuantity
  })
}

// Funcion para extraer el lote y la fecha de vencimiento del proveedor OFFIMEDICAS S.A.
const extraerLoteFechaVencimientoOffimedicas = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /Lote\s+(\S+)/
  const venceRegex = /Vence\s+(\d{2}\/\d{2})/

  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)

  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? venceMatch[1] : null

  // Formatear la fecha de vencimiento al formato deseado (YYYY-MM-DD)
  const formattedExpDate = vence ? `20${vence.substr(3, 2)}-${vence.substr(0, 2)}-01` : null

  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Funcion para extraer el lote y la fecha de vencimiento del proveedor EVE DISTRIBUCIONES
const extraerLoteFechaVencimientoEveDistribuciones = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /LOTE:\s+(\S+)/
  const venceRegex = /FECHA VENC:\s+(\d{4})(\d{2})(\d{2})/

  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)

  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? `${venceMatch[1]}${venceMatch[2]}${venceMatch[3]}` : null

  // La fecha viene así 20250530, se debe formatear al formato deseado (YYYY-MM-DD)
  const formattedExpDate = vence ? `${vence.substring(0, 4)}-${vence.substring(4, 6)}-${vence.substring(6, 8)}` : null

  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Funcion para extraer el lote y la fecha de vencimiento del proveedor MAS QUE INSUMOS
const extraerLoteFechaVencimientoMasQueInsumos = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /LOTE\s+([^V.]+)/
  const venceRegex = /V\.(\d{1,2}\.\d{1,2}\.\d{4})/

  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)

  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? venceMatch[1] : null

  // Formatear la fecha al formato YYYY-MM-DD
  const formattedExpDate = vence
    ? vence.split('.').map(part => part.padStart(2, '0')).reverse().join('-')
    : null

  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Funcion para obtener la información de los lotes
const getBatchInfo = (proveedor, description, invoicedQuantity, priceBaseQuantity, itemInstance) => {
  if (proveedor === 'OFFIMEDICAS S.A.') {
    const infoLotes = extraerLoteFechaVencimientoOffimedicas(description[0].trim())
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedor === 'MAS QUE INSUMOS  SAS') {
    const infoLotes = extraerLoteFechaVencimientoMasQueInsumos(description[0].trim())
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedor === 'EVE DISTRIBUCIONES S.A.S') {
    const infoLotes = extraerLoteFechaVencimientoEveDistribuciones(description[0])
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else {
    return itemInstance ? itemInstance[0]['cac:LotIdentification'].map((l) => {
      return {
        ExpDate: l['cbc:ExpiryDate'][0] || '',
        DistNumber: l['cbc:LotNumberID'][0] || '',
        Quantity: parseInt(priceBaseQuantity[0]._) || 0
      }
    }) : [{
      ExpDate: '',
      DistNumber: '',
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  }
}

// Funcion para generar el objeto de los items
const generarObjItems = (x, index, BatchLotes) => {
  return {
    Index: index + 1,
    Description: x['cac:Item'][0]['cbc:Description'][0].trim(),
    IdentificationItem: x['cac:Item'][0]['cbc:Description'][0].trim(),
    CodCum: x['cbc:Note'] ? x['cbc:Note'][0].split('|')[2] ?? 0 : '',
    Presentacion: x['cbc:Note'] ? x['cbc:Note'][0].split('|')[1] ?? '' : '',
    Laboratorio: x['cbc:Note'] ? x['cbc:Note'][0].split('|')[3] ?? '' : '',
    BatchNumbers: BatchLotes,
    InvoiceQuantity: parseInt(x['cbc:InvoicedQuantity'][0]._) ?? 0,
    Price: parseInt(x['cac:Price'][0]['cbc:PriceAmount'][0]._) ?? 0,
    LineTotal: parseInt(x['cbc:LineExtensionAmount'][0]._) ?? 0,
    LineTotalCalculado: 0,
    InfoItem: null,
    InfoBodega: null,
    LineNum: null
  }
}

/* Funcion para asignar la data del xml al objeto */
const handleFileUpload = (file) => {
  const reader = new FileReader()

  reader.onload = async () => {
    const xmlContent = reader.result
    const result = convertXmlToJson(xmlContent)
    if (!result || !result.AttachedDocument) {
      await Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'El archivo ingresado no es XML válido para facturación electrónica por favor valide e inténtelo nuevamente',
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
      })
      return
    }
    const xmlDescription = convertXmlToJson(result.AttachedDocument['cac:Attachment'][0]['cac:ExternalReference'][0]['cbc:Description'])
    console.log('xmlDescription --> ', xmlDescription)
    // Invoice.value.PedidoReferencia = xmlDescription.Invoice['cac:OrderReference'][0]['cbc:ID'][1]

    const proveedor = xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0]['cac:PartyName'][0]['cbc:Name'][0]
    console.log('Proveedor --> ', proveedor)

    Invoice.value.Items = xmlDescription.Invoice['cac:InvoiceLine'].map((x, index) => {
      // console.log('Invoice line --> ', x)
      const BatchLotes = getBatchInfo(proveedor, x['cac:Item'][0]['cbc:Description'], x['cbc:InvoicedQuantity'], x['cac:Price'][0]['cbc:BaseQuantity'], x['cac:Item'][0]['cac:ItemInstance'])

      return generarObjItems(x, index, BatchLotes)
    })

    // console.log('Invoice items value --> ', Invoice.value.Items)
    Invoice.value.InvoiceNumber = xmlDescription.Invoice['cbc:ID'][0]
    Invoice.value.DocDate = xmlDescription.Invoice['cbc:IssueDate'][0]
    Invoice.value.DocTotal = parseInt(xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:LineExtensionAmount'][0]._)
    Invoice.value.DocTotalBeDi = parseInt(xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:LineExtensionAmount'][0]._)
    Invoice.value.TaxTotal = xmlDescription.Invoice['cac:TaxTotal'] ? parseInt(xmlDescription.Invoice['cac:TaxTotal'][0]['cbc:TaxAmount'][0]._) : 0
    Invoice.value.CardName = proveedor
    Invoice.value.Nit = xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0]['cac:PartyTaxScheme'][0]['cbc:CompanyID'][0]._ ?? 0
    Invoice.value.consecutivoNit = xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0]['cac:PartyTaxScheme'][0]['cbc:CompanyID'][0].$.schemeID ?? 0
    Invoice.value.NitCompleto = Invoice.value.Nit + '-' + Invoice.value.consecutivoNit
    Invoice.value.Tipo = Invoice.value.Items.every((a) => a.BatchNumbers.length > 0) ? 'Auto' : 'Manual'
    Invoice.value.TipoLotes = 'unico'
    Invoice.value.JournalMemo = `Factura N° ${Invoice.value.InvoiceNumber} ${Invoice.value.CardName}`
  }

  reader.readAsText(file)
}

/* Funcion para convertir XML a Json */
const convertXmlToJson = (xmlContent) => {
  let resultado = ''
  parseString(xmlContent, (error, result) => {
    if (error) {
      resultado = ('Error al convertir XML a JSON:', error)
    } else {
      resultado = result
    }
  })
  return resultado
}

/**/
const dragOver = (event) => {
  event.preventDefault()
  isDragging.value = true
}

/**/
const dragLeave = (event) => {
  event.preventDefault()
  isDragging.value = false
}

/**/
const handleDrop = (event) => {
  event.preventDefault()
  isDragging.value = false
  const file = event.dataTransfer.files[0]
  if (file.type === 'text/xml') {
    handleFileUpload(file)
  } else {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'El archivo ingresado no es de tipo XML por favor valide e inténtelo nuevamente',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })
  }
}

/* Funcion para obtener las bodegas */
const getBodegas = async () => {
  await _RecepcionPedidosService.value.getBodegas().then(({ data }) => {
    bodegas.value = data
  }).catch((err) => {
    console.error(err.message)
  })
}

/* Funcion para buscar las bodegas */
const searchBodegas = async (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBodegas.value = [...bodegas.value]
    } else {
      filteredBodegas.value = bodegas.value.filter((bodega) => {
        // Buscar por bodega o codigo
        return bodega.bodega.toUpperCase().includes(event.query.toUpperCase())
      })
    }
  }, 250)
}

/* Funcion para buscar el Mx */
const searchMx = async ({ query }) => {
  if (!query.trim() || query.length <= 3) {
    medicamentosList.value = []
  } else {
    await _RecepcionPedidosService.value.getAllMds(query.toUpperCase()).then(({ data }) => {
      loadingItems.value = false
      medicamentosList.value = data
    }).catch((err) => {
      console.error(err.message)
    })
  }
}

/* Validaciones y envio de la data */
const onSubmit = () => {
  // Validar que todos los items tengan medicamento y bodega
  const items = Invoice.value.Items.filter((x) => x.InfoItem && x.InfoBodega)
  if (items.length !== Invoice.value.Items.length) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'Debe seleccionar un medicamento y una bodega para todos los items de la factura',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })

    return
  }

  // Validar que todos los items tengan lote
  const itemsLote = Invoice.value.Items.every((x) => x.BatchNumbers.length > 0 && x.BatchNumbers.every((y) => y.DistNumber && y.ExpDate))
  if (!itemsLote) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'Debe asignar y completar la información completa del lote para todos los artículos de la factura',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })

    return
  }

  // Validar que todos los items tengan la cantidad total sumando la cantidad de los lotes
  const itemsCantidad = Invoice.value.Items.every((x) => {
    const cantidadLotes = x.BatchNumbers.reduce((a, b) => a + parseInt(b.Quantity), 0)
    return parseInt(x.InvoiceQuantity) === cantidadLotes
  })
  if (!itemsCantidad) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'La cantidad total de los lotes debe ser igual a la cantidad total del artículo',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })

    return
  }

  // Validar que el valor total de las lineas no supere el valor total de la factura
  const totalLineas = Invoice.value.Items.reduce((a, b) => a + b.LineTotalCalculado, 0)
  if (totalLineas !== Invoice.value.DocTotal) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: `El valor total de las lineas ${helper.formatCurrency(totalLineas)} debe ser igual al valor total de la factura ${helper.formatCurrency(Invoice.value.DocTotal)}`,
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })

    return
  }

  messageConfirmSave('', 'Información', 'warning', 'Esta seguro de crear la preliminar para la entrada de mercancia').then(({ isConfirmed }) => {
    if (isConfirmed) {
      _RecepcionPedidosService.value.crearPreliminarEntrada(Invoice.value).then(({ data }) => {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: `La preliminar de entrada #${data.Id} se ha creado correctamente`
        }).then(() => {
          router.push({ name: 'pharmasan.compras.logistica.cargue-facturas.listado' })
        })
      }).catch((err) => {
        console.error(err.message)
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    }
  })
}

/**/
const onReset = () => {
  Swal.fire({
    icon: 'question',
    title: '¿Esta seguro/a?',
    text: '¿Desea eliminar el contenido de esta tabla?',
    showConfirmButton: true,
    focusCancel: true,
    showCancelButton: true,
    confirmButtonText: 'Si, eliminar',
    cancelButtonText: 'No, cancelar'
  }).then((resp) => {
    if (resp.isConfirmed) {
      Invoice.value = {
        InvoiceNumber: null,
        CardName: null,
        DocDate: null,
        Comments: null,
        JournalMemo: null,
        DocTotal: null,
        DocTotalBeDi: null,
        TaxTotal: null,
        DiscTotal: 0,
        Items: []
      }
    }
  })
}

/**/
onMounted(async () => {
  await getBodegas()
})

</script>

<template>
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <!-- Titulo Card -->
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-lg mr-auto">{{ 'Nuevo Cargue de Factura XML' }}</h2>
          <Button
            label="Eliminar cargue"
            icon="pi pi-trash"
            @click="onReset"
            size="small"
            severity="danger"
            class="p-button-rounded"
            v-if="Invoice.Items.length !== 0"
          />
        </div>
        <!-- Body Card -->
        <div class="p-4">
          <!-- Dropzone -->
          <div id="drop-zone" class="p-2" v-if="Invoice.Items.length === 0">
            <div class="drop-zone" :class="{ 'hover': isDragging }" @dragover="dragOver" @dragleave="dragLeave"
                 @drop="handleDrop">
              Arrastre su archivo XML hasta aquí
            </div>
            <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" multiple>
          </div>

          <!-- Información factura -->
          <div class="grid grid-cols-12 gap-6 p-2" v-else>
            <div class="col-span-12">
              <Card class="p-custom-card">
                <template #title>FACTURA {{ Invoice.InvoiceNumber }}</template>
                <template #content>
                  <div class="flex justify-between pb-4">
                    <div>
                      <div style="font-size: smaller;">
                        <strong>Nombre Proveedor: </strong>{{ Invoice.CardName }}
                      </div>
                      <div style="font-size: smaller;">
                        <strong>Fecha Factura: </strong>{{ Invoice.DocDate }}
                      </div>
                      <div style="font-size: smaller;">
                        <strong>Items Factura: </strong>{{ Invoice.Items.length }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <DataTable
                      :value="Invoice.Items"
                      class="p-datatable-sm text-xs custom-scrollbar"
                      showGridlines
                      dataKey="Index"
                      responsiveLayout="scroll"
                      scrollHeight="500px"
                      :scrollable="true"
                      ref="tableList"
                      removableSort
                    >
                      <template #empty>
                        No se encontraron datos registrados.
                      </template>
                      <Column
                        field="Description"
                        header="Descripción Articulo"
                        style="min-width:32rem">
                        <template #body="slotProps">
                          <div class="flex-col">
                            <div>
                              <strong>Nombre: </strong>{{ slotProps.data.Description }}
                            </div>
                            <div>
                              <strong>Laboratorio: </strong>{{ slotProps.data.Laboratorio }}
                            </div>
                            <div>
                              <strong>Presentación: </strong>{{ slotProps.data.Presentacion }}
                            </div>
                            <div>
                              <strong>Cod. Cum: </strong>{{ slotProps.data.CodCum }}
                            </div>
                            <div
                              class="pt-2"
                              v-if="slotProps.data.U_PHR_ItemName">
                              <strong>Medicamento en pedido: </strong>
                            </div>
                            <div>
                              <span>{{ slotProps.data.U_PHR_ItemName }}</span>
                            </div>
                            <div class="flex justify-between gap-2">
                              <div class="flex flex-col w-2/3">
                                <span class="font-bold mb-1">Medicamento en factura: </span>
                                <AutoComplete
                                  v-model="slotProps.data.InfoItem"
                                  :suggestions="medicamentosList"
                                  @complete="searchMx"
                                  optionLabel="nombreCompleto"
                                  minLength="4"
                                  emptySearchMessage="No se encontraron resultados"
                                  forceSelection
                                  :loading="loadingItems"
                                  placeholder="Digite al menos 4 caracteres para buscar un Medicamento..."
                                  class="text-xs w-full"
                                  inputClass="w-full"
                                  :pt="{
                                    root: { style: 'height: 1.7rem' },
                                    input: { style: 'font-size: 0.75rem' },
                                    panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                                    item: { style: 'word-wrap: break-word;' }
                                  }"
                                />
                              </div>
                              <div class="flex flex-col w-1/3">
                                <span class="font-bold mb-1">Bodega: </span>
                                <AutoComplete
                                  v-model="slotProps.data.InfoBodega"
                                  :suggestions="filteredBodegas"
                                  @complete="searchBodegas"
                                  optionLabel="bodega"
                                  optionValue="WhsCode"
                                  minLength="1"
                                  emptySearchMessage="No se encontraron resultados"
                                  forceSelection
                                  dropdown
                                  :loading="loadingItems"
                                  placeholder="Digite al menos 1 caracter para buscar la bodega..."
                                  class="text-xs w-full"
                                  inputClass="w-full"
                                  :pt="{
                                    root: { style: 'height: 1.7rem' },
                                    input: { style: 'font-size: 0.75rem' },
                                    panel: { style: 'font-size: 0.75rem' }
                                  }"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column
                        field="Quantity"
                        header="Valores"
                        style="width:16rem">
                        <template #body="{ data }">
                          <div class="flex-col w-full">
                            <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                              <span class="font-bold">Cant. Total:</span>
                              <InputText
                                id="InvoiceQuantity"
                                type="number"
                                :min="1"
                                class=""
                                placeholder="Ingresa la cantidad..."
                                @change="asignarCantidad(data)"
                                v-model="data.InvoiceQuantity"
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem; font-size: 0.75rem;'
                                  })
                                }"
                              />
                            </div>
                            <div class="flex flex-wrap justify-between items-center mb-2">
                              <span class="font-bold">Vlr Und:</span>
                              <InputNumber
                                v-model="data.Price"
                                inputId="currency-colombia"
                                mode="currency"
                                class=""
                                currency="COP"
                                locale="es-CO"
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem'
                                  }),
                                  input: { style: 'font-size: 0.75rem; text-align: right' }
                                }"
                              />
                            </div>
                            <div class="flex flex-wrap justify-between items-center">
                              <span class="font-bold w-1/3">Vlr Total: </span>
                              <span class="mr-3"> {{ $h.formatCurrency(calcularTotalLinea(data), 2) }}</span>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column
                        field="BatchNumber"
                        header="Lote"
                        :bodyStyle="`${Invoice.Tipo === 'Manual' ? 'justify-content: center; text-align: center' : ''}`"
                        style="width:16rem">
                        <template #body="{data}">
                          <div v-if="Invoice.Tipo === 'Manual' && Invoice.TipoLotes !== 'unico'">
                            <div v-if="data.BatchNumbers.length === 0">
                              <Button
                                type="button"
                                class="p-button-xs"
                                icon="pi pi-check"
                                label="Asignar"
                                @click="openModalAsignarLotes(data)"/>
                            </div>
                            <div
                              v-if="data.BatchNumbers.length > 0"
                              class="flex justify-center">
                              <div class="mr-3">
                                <div>
                                  <Button
                                    type="button"
                                    class="p-button-xs"
                                    icon="pi pi-eye"
                                    label="Ver"
                                    @click="toggle($event,data)"
                                    aria-haspopup="true"
                                    aria-controls="overlay_panel"
                                  />
                                  <OverlayPanel
                                    ref="op"
                                    appendTo="body"
                                    :showCloseIcon="true"
                                    id="overlay_panel"
                                    style="width: 500px"
                                    :breakpoints="{'960px': '75vw'}"
                                  >
                                    <DataTable
                                      :value="infoLotes"
                                      class="p-datatable-sm text-xs"
                                      showGridlines
                                      dataKey="id"
                                      scrollHeight="500px"
                                      :scrollable="true"
                                      responsiveLayout="scroll"
                                    >
                                      <Column
                                        field="DistNumber"
                                        header="Lote"
                                        headerStyle="justify-content: center;"
                                        style="min-width:5rem"
                                        bodyStyle="text-align: center"/>
                                      <Column
                                        field="Quantity"
                                        header="Cant."
                                        headerStyle="justify-content: center;"
                                        style="min-width:2rem"
                                        bodyStyle="text-align: center">
                                        <template #body="{data}">
                                          <span>{{ $h.formatNumber(data.Quantity) }}</span>
                                        </template>
                                      </Column>
                                      <Column
                                        field="ExpDate"
                                        header="F.Venc."
                                        headerStyle="justify-content: center;"
                                        style="min-width:5rem"
                                        bodyStyle="text-align: center"
                                      />
                                    </DataTable>
                                  </OverlayPanel>
                                </div>
                              </div>
                              <div>
                                <Button
                                  type="button"
                                  class="p-button-xs p-button-warning"
                                  v-tooltip.top="'Editar Lotes'"
                                  icon="pi pi-pencil"
                                  @click="openModalAsignarLotes(data)"/>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="flex-col"
                            v-for="(item) in data.BatchNumbers"
                            :key="item.indice"
                          >
                            <div class="flex flex-wrap justify-between items-center mb-1">
                              <span class="font-bold">Lote:</span>
                              <InputText
                                id="BatchNumber"
                                type="text"
                                class=""
                                v-model="item.DistNumber"
                                placeholder="Ingresa el lote..."
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem; font-size: 0.75rem;'
                                  })
                                }"
                              />
                            </div>

                            <div class="flex flex-wrap justify-between items-center mb-1">
                              <span class="font-bold">Fec. Venc:</span>
                              <InputText
                                id="ExpDate"
                                type="date"
                                class=""
                                v-model="item.ExpDate"
                                placeholder="Ingresa la fecha de vencimiento..."
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'width:72%; height: 1.7rem; font-size: 0.75rem;'
                                  })
                                }"
                              />
                            </div>

                            <div class="flex flex-wrap justify-between items-center">
                              <span class="font-bold">Cantidad: </span>
                              <InputNumber
                                id="InvoiceQuantity"
                                :min="1"
                                disabled
                                class=""
                                v-model="item.Quantity"
                                :useGrouping="false"
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem'
                                  }),
                                  input: { style: 'font-size: 0.75rem; text-align: right' }
                                }"
                              />
                            </div>
                          </div>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </template>
                <template #footer>
                  <div class="w-full p-2 grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-8 lg:col-span-2">
                      <div class="flex flex-col">
                        <p class="text-xs mb-2">
                          <strong>Comentarios: </strong>
                        </p>
                        <Textarea
                          class="p-inputtext-xs"
                          :autoResize="true"
                          v-model="Invoice.Comments"
                          rows="3"
                          cols="20"
                        />
                      </div>
                      <div class="flex flex-col">
                        <p class="text-xs mb-2">
                          <strong>Entrada de diario: </strong>
                        </p>
                        <Textarea
                          class="p-inputtext-xs"
                          disabled
                          :autoResize="true"
                          v-model="Invoice.JournalMemo"
                          rows="3"
                          cols="20"
                        />
                      </div>
                    </div>
                    <div class="text-xs lg:col-span-1">
                      <div class="flex justify-between border-b-2 pb-3">
                        <span class="text-gray-600">Sub Total Factura: </span>
                        <span class="">{{ $h.formatCurrency(Invoice.DocTotalBeDi) }}</span>
                      </div>
                      <div class="flex justify-between my-3">
                        <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
                        <span class="">{{ $h.formatCurrency(Invoice.DiscTotal) }}</span>
                      </div>
                      <div class="flex justify-between my-3">
                        <span class="text-gray-600">Total IVA:</span>
                        <span class="">{{ $h.formatCurrency(Invoice.TaxTotal) }}</span>
                      </div>
                      <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
                        <span class="font-bold">Total Factura:</span>
                        <span class="">{{ $h.formatCurrency(Invoice.DocTotal) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <Button
    v-if="Invoice.Items.length > 0"
    label="Crear Preliminar"
    icon="pi pi-save"
    class="p-button-raised p-button-success floating-button p-button-rounded"
    @click="onSubmit"
  />

  <ModalAsignarLotes ref="modalAsignarLotes" />

</template>

<style scoped lang="scss">
::v-deep(.p-column-header-content) {
    justify-content: center;
}

.drop-zone {
    width: 400px;
    height: 200px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    color: #888;
    line-height: 150px;
    margin: 0 auto;
}

.floating-button {
    position: fixed !important;
    bottom: 35px;
    right: 40px;
    z-index: 1050;
}

.drop-zone.hover {
    border-color: #aaa;
    background: #f8f8f8;
    color: #555;
}

.p-custom-card {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19);
}

.custom-scrollbar ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 5px;
}
</style>
