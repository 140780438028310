<script setup>
import { defineExpose, onMounted, reactive, ref } from 'vue'
import Swal from 'sweetalert2'

const displayModal = ref(false)
const item = ref([])
const editingRows = ref([])
const lote = reactive({
  DistNumber: null,
  ExpDate: null,
  Quantity: null
})

// Funcion para abrir el modal
const openModal = async (data) => {
  displayModal.value = true
  item.value = data
}

// Funcion para cerrar el modal
const closeModal = async () => {
  displayModal.value = false
}

// Funcion para guardar los lotes
const onRowEditSave = (event) => {
  const { newData, index } = event

  item.value.BatchNumbers[index] = newData
}

// Funcion para agregar un lote
const addBatchNumber = () => {
  if (!lote.DistNumber || !lote.ExpDate || !lote.Quantity) {
    Swal.fire({
      icon: 'info',
      text: 'Por favor ingrese un lote con su información completa.',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })
    return
  }

  // Validar de que si la cantidad de la suma de los lotes es mayor a la cantidad total del item
  const totalQuantityLotes = item.value.BatchNumbers.reduce((acc, curr) => acc + curr.Quantity, 0)

  if (totalQuantityLotes + lote.Quantity > item.value.InvoiceQuantity) {
    Swal.fire({
      icon: 'warning',
      text: `La cantidad de los lotes (${totalQuantityLotes + lote.Quantity}) no puede ser mayor a la cantidad total del artículo (${item.value.InvoiceQuantity}).`,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })
    return
  }

  item.value.BatchNumbers.push({
    Quantity: lote.Quantity,
    DistNumber: lote.DistNumber,
    ExpDate: lote.ExpDate
  })

  lote.DistNumber = null
  lote.Quantity = null
  lote.ExpDate = null

  console.log('lote ---> ', lote)
}

defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  console.log('onMounted, modal asignacion de lotes...')
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    modal
    :closable="false"
    maximizable
    :closeOnEscape="false"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '60vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Asignar Lotes` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid grid-cols-12 text-xs bg-gray-300 rounded gap-2">
      <div class="col-span-9 p-2">
        <div class="flex flex-col">
          <span class="font-bold">Medicamento: </span>
          <span class="font-normal">{{ item.Description }}</span>
        </div>
      </div>
      <div class="col-span-3 p-2">
        <div class="flex flex-col">
          <span class="font-bold">Cantidad Total: </span>
          <span>{{ $h.formatNumber(item.InvoiceQuantity) }}</span>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-12 my-3">
      <div class="sm:col-span-6">
        <div class="flex flex-col">
          <label for="BatchNumber" class="block text-sm font-medium text-gray-700 mb-1">Lote:</label>
          <InputText
            id="BatchNumber"
            type="text"
            class="w-full"
            v-model="lote.DistNumber"
            placeholder="Ingresa el lote..."
            :pt="{
              root: ({
                props, state, context
              }) => ({
                style: 'height: 1.7rem; font-size: 0.75rem;'
              })
            }"
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="flex flex-col">
          <label for="ExpDate" class="block text-sm font-medium text-gray-700 mb-1">Fecha de Vencimiento:</label>
          <InputText
            id="ExpDate"
            type="date"
            class="w-full"
            v-model="lote.ExpDate"
            placeholder="Ingresa la fecha de vencimiento..."
            :pt="{
              root: ({
                props, state, context
              }) => ({
                style: 'height: 1.7rem; font-size: 0.75rem;'
              })
            }"
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="flex flex-col w-full">
          <label for="Quantity" class="block text-sm font-medium text-gray-700 mb-1">Cantidad:</label>
          <InputNumber
            id="Quantity"
            :min="1"
            class="w-full"
            v-model="lote.Quantity"
            placeholder="Ingresa la cantidad..."
            :useGrouping="false"
            :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 1.7rem'
                }),
                input: { style: 'font-size: 0.75rem;' }
              }"
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="flex items-end w-full h-full">
          <Button
            icon="pi pi-plus"
            label="Agregar Lote"
            class="p-button-outlined p-button-success p-button-xs mt-auto w-full"
            @click="addBatchNumber"
            :pt="{
              root: { style: 'height: 1.7rem'},
            }"
          />
        </div>
      </div>

      <!-- Listado de lotes seleccionados -->
      <div class="sm:col-span-12">
        <label for="" class="block text-sm font-medium text-gray-700 mb-2">Listado de lotes seleccionados:</label>
        <DataTable
          ref="dt"
          v-model:editingRows="editingRows"
          :value="item.BatchNumbers"
          tableStyle="min-width: 50rem"
          class="p-datatable-sm text-xs"
          showGridlines
          editMode="row"
          dataKey="id"
          @row-edit-save="onRowEditSave"
        >
          <template #empty> No hay datos para mostrar. </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column field="DistNumber" header="Lote" style="width: 40%">
            <template #editor="{ data, field }">
              <InputText
                v-model="data[field]"
                class="w-full"
                :pt="{
                  root: {
                    style: 'height: 1.7rem; font-size: 0.75rem;'
                  }
                }"
              />
            </template>
          </Column>
          <Column field="ExpDate" header="Fecha de Vencimiento" style="width: 30%">
            <template #editor="{ data, field }">
              <InputText
                type="date"
                class="w-full"
                v-model="data[field]"
                :pt="{
                  root: {
                    style: 'height: 1.7rem; font-size: 0.75rem;'
                  }
                }"
              />
            </template>
          </Column>
          <Column field="Quantity" header="Cantidad" style="width: 20%">
            <template #editor="{ data, field }">
              <InputNumber
                :min="1"
                class="w-full"
                v-model="data[field]"
                :useGrouping="false"
                :pt="{
                  root: {
                    style: 'height: 1.7rem'
                  },
                  input: { style: 'font-size: 0.75rem;' }
                }"
              />
            </template>
          </Column>
          <Column :rowEditor="true" style="width: 5%; min-width: 8rem" bodyStyle="text-align:center"></Column>
          <!-- Columna de acciones -->
          <Column style="width: 5%; min-width: 5rem" bodyStyle="text-align:center">
            <template #body="{ data }">
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-xs"
                @click="item.BatchNumbers.splice(data.rowIndex, 1)"
                v-tippy="{ content: 'Eliminar' }"
                :pt="{
                  root: { style: 'width: 1.7rem; height: 1.7rem'},
                }"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <!-- <pre class="text-xxs">{{ JSON.stringify(item, null, '\t') }}</pre> -->

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Confirmar" icon="pi pi-check" @click="closeModal" class="p-button-xs p-button-success" autofocus/>
    </template>
  </Dialog>
</template>

<style scoped>

</style>
